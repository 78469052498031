import React from 'react';
import { useState, useEffect } from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import screen from '../assets/images/screen.png';
import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';

import section1Img from '../assets/images/1-min.png'
import section2Img from '../assets/images/2-min.png'
import section3Img from '../assets/images/3-min.png'
import section4Img from '../assets/images/4-min.png'
import bannerImg from '../assets/images/Banner.jpg'

import ChillNowImg from '../assets/images/ChillNow.png'
import SensImg from '../assets/images/Sens.png'
import WashowImg from '../assets/images/Washow.png'

import revtelLogo from '../assets/images/revtelLogo.png';

import { Link } from 'gatsby';
import YouTube from 'react-youtube';

const IndexPage = () => {
  const windowSize = useWindowSize();
  let ytSize = null
  if (windowSize.width) {
    const MIN_YT_LEN = 200
    let w = (windowSize.width) * 0.75 > MIN_YT_LEN ? (windowSize.width) * 0.75 : MIN_YT_LEN
    ytSize = {
      w, h: w * (9/16) 
    }
  }

  return (
  <Layout>
    <header id="header">

      <div style={{position:'absolute', top: 2, left: 2, width: 200, height:25, }}>
        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
          <img src={revtelLogo} style={{width: 20, height:20, marginRight:5}} />
          <a href="https://www.revtel.tech/" target='_blank'>RevtelTech 忻旅科技</a>
        </div>
      </div>

      <div className="content">
        <h1>
          <a href="/#">{config.heading}</a>
        </h1>
        <p>{config.subHeading}</p>
      </div>
      <div className="image phone">
        <div className="inner">
          <img src={bannerImg} alt="" />
        </div>
      </div>
    </header>

    <section id="one" className="wrapper style2 special">
      <header className="major">
        <h2>示範影片</h2>
      </header>
      <ul className="icons major">
        <p>Type 1: CW version - 支援區塊鏈簽署功能</p>
        <li>
          {ytSize && <YouTube videoId="L4JRQt7jmdQ" opts={{width:ytSize.w, height:ytSize.h}} />}
        </li>
      </ul>
    </section>

    <section id="two" className="wrapper">
      <div className="inner alt">
        <section className="spotlight">
          <div className="image">
            <img src={section1Img} alt="" />
          </div>
          <div className="content">
            <h3>NFC 手機互動</h3>
            <p>
              免 APP 安裝即可感應，縮短互動前置時間。<br/>
              Android / iOS 雙平台支援，最大化應用場景。
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={section2Img} alt="" />
          </div>
          <div className="content">
            <h3>離線 OTP 身份識別</h3>
            <p>
              每次感應回傳不同認證碼，杜絕偽造可能。<br/>
              硬體支援防竄改，可偵測實體物品是否被破壞。
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={section3Img} alt="" />
          </div>
          <div className="content">
            <h3>APP 擴充無限可能</h3>
            <p>
              團隊具有豐富軟硬整合開發經驗，可協助開發專屬 APP 及雲端系統。<br/>
              一站式滿足從身份認證到不同商業模式的多面向業務需求。
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={section4Img} alt="" />
          </div>
          <div className="content">
            <h3>多種實體封裝形式</h3>
            <p>
              從貼紙到卡片皆支援，針對您的需求可提供多種裝置形式。<br/>
              並可根據需求進行相關印刷及組合。
            </p>
          </div>
        </section>
      </div>
    </section>

    <section id="one" className="wrapper style2 special">
      <header className="major">
        <h2>合作夥伴</h2>
      </header>
      <ul className="icons major">
        <li>
          <img
            src={ChillNowImg}
            style={{ width: 100, height: 100 }}
          />
        </li>
        <li>
          <img
            src={SensImg}
            style={{ width: 100, height: 100 }}
          />
        </li>
        <li>
          <img
            src={WashowImg}
            style={{ width: 100, height: 100 }}
          />
        </li>
      </ul>
    </section>

    <section id="three" className="wrapper style2 special">
      <header className="major">
        <h2>聯絡我們</h2>
        <p>歡迎與我們聯繫取得更多資訊</p>
      </header>
      <ul className="actions special">
        <li>
          <a
            href="mailto:contact@revteltech.com"
            className="button primary icon fa-plus"
          >
            現在就了解！
          </a>
        </li>
      </ul>
    </section>

    <Footer />
  </Layout>
)
  }

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  return windowSize;
}

export default IndexPage;
